.contenedorNotFound{
    width: 80%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin: 0 auto;
}

.contenedorNotFound img {
    width: 100%;
    border-radius: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}