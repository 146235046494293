.spacer {
    margin-bottom: 50px;
  }
  .center {
    text-align: center !important;
  }
  .dentroCirculo:hover {
   color: #785aac !important;
  }
  
  .circulo {
    color: #ecf0f1;
  }
  .dentroCirculo {
    color: #6ea5e0 !important;
  }
  
  .h3-3col {
    font-weight: bold;
    font-size: 22px;
    /* color: var(--tertiary); */
  }
  .p-3col {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px;
    color: #666 !important;
  }
  
  .tituloPrincipal {
    margin-top: 10px;
    font-size: 50px;
    font-weight: 600;
  }


  @media (max-width: 600px) {
    .tituloPrincipal {
      margin-top: 20px;
      font-size: 30px;
      font-weight: 500;
    }
}