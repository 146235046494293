.subtitulo2 {
    font-size: 1.8em;
    font-weight: 500;
    color: #ff6600;
    text-align: center !important;
    margin: 20px;

}



.parrafo2 {
    font-size: 18px;
    line-height: 1.5;
    margin: 30px;
    text-align: initial;

}