.preNav {
    background-color: #2b71ba;
    color: white;
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 15px;
}

.carta {
    width: 20%;
    margin-right: 10px;
    color: white;
}